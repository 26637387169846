import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import IEventoDTO from "interfaces/service/models/IEventoDTO";
import IEventoRequest from "interfaces/service/requests/IEventoRequest";
import IEventosAdminPorUsuarioCicloRequest from "interfaces/service/requests/IEventosAdminPorUsuarioCicloRequest";
import IEventosAdminPorUsuarioRequest from "interfaces/service/requests/IEventosAdminPorUsuarioRequest";
import IEventosVisitadorRequest from "interfaces/service/requests/IEventosVisitadorRequest";

export async function edit(params: any) {
    console.log(params);
    const result = await axios.post(API_RESOURCES.EVENTOS.BASE + '/admin/edit', params)
    return result
}

export async function create(eventRequest: IEventoRequest) {
    const result = await axios.post(API_RESOURCES.EVENTOS.BASE, eventRequest)
    return result
}

export async function consultaUsuario(params: IEventosVisitadorRequest) {
    const result = await axios.get<IEventoDTO[]>(API_RESOURCES.EVENTOS.BASE, {
        params
    })
    return result.data
}

export async function consultaAdminPorUsuario(params: IEventosAdminPorUsuarioRequest) {
    const result = await axios.get<IEventoDTO[]>(API_RESOURCES.EVENTOS.BASE + '/admin/user', {
        params: {
            fecha: params.date,
            user_id: params.user_id
        }
    })
    return result.data
}

export async function consultaAdminPorUsuarioCiclo(params: IEventosAdminPorUsuarioCicloRequest) {
    const result = await axios.get<IEventoDTO[]>(API_RESOURCES.EVENTOS.BASE + '/admin/ciclo', {
        params: {
            ...params
        }
    })
    return result.data
}

export async function destoy(id: number, month: string) {
    const result = await axios.delete(`${API_RESOURCES.EVENTOS.BASE}/${id}/${month}`)
    return result.data
}