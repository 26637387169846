import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from '../pages/login/login'
import UNAUTHENTICATED_ROUTES from 'routing/unauthenticated-routes'

function UnauthenticatedContent() {
    return (
        <Routes>
            <Route path={UNAUTHENTICATED_ROUTES.LOGIN} element={<Login/>}/>
            <Route path="*" element={<Navigate replace to={UNAUTHENTICATED_ROUTES.LOGIN} />} />
        </Routes>
    )
}

export default UnauthenticatedContent
