import React from 'react'
import { Alert } from 'react-bootstrap'

type ErrorMessagePropsType = {
    message: string
    className?: string
}

const ErrorMessage = ({ message, className }: ErrorMessagePropsType) => {
    return (
        <Alert variant="danger" className={`no-print ${className ? className : ''}`}>
            {message}
        </Alert>
    )
}

export default ErrorMessage
