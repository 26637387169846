import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import './login.scss'

type LoginPropsTypes = {
    children?: React.ReactElement
}

const LoginCard = ({ children }: LoginPropsTypes) => {

    return <div className="mt-2">
        <Container>
            <Row className="vh-100 d-flex justify-content-center align-items-center">
                <Col xs={10} lg={10}>
                    <Card className="d-flex login-card">
                        <Row className="flex-grow-1">
                            <Col className="px-0 d-none d-lg-flex" xs={5}>
                                <img
                                    src="./img/login-img.jpg"
                                    alt="login form"
                                    className="img-fluid left-image h-100 w-100" />
                            </Col>
                            <Col xs={12} lg={7} className="position-relative">
                                {children}
                                <div className="copyright">
                                    <p className="small text-muted text-center">
                                        Copyright © SNC PHARMA {new Date().getFullYear()}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

        </Container>
    </div>;
};

export default LoginCard;
