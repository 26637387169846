import APP_SETTINGS from "constants/app-settings"
import { useEffect } from "react"

const useTitle = (title?: string) => {
    useEffect(() => {
        if(!title){
            document.title = APP_SETTINGS.TITLE
        }else{
            document.title = `${title} | ${APP_SETTINGS.TITLE}`
        }
    }, [])
    
}

export default useTitle