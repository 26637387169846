import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import IPaginatedResponse from "interfaces/service/models/IPaginatedResponse";
import IRevisita from "interfaces/service/models/IRevisita";
import IPaginatedRequest from "interfaces/service/requests/IPaginatedRequest";
import IRevisitasRequest from "interfaces/service/requests/IRevisitasRequest";

export async function getPaginated(params: IRevisitasRequest & IPaginatedRequest){
    const result = await axios.get<IPaginatedResponse<IRevisita>>(API_RESOURCES.REVISITAS.BASE, {
        params
    })

    return result.data
}