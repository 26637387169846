import axios from "axios";
import { rootStore } from ".";

axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.baseURL = process.env.REACT_APP_API_URL

axios.interceptors.request.use((request)=>{
    if(rootStore.authStore.token){
        request.headers = {
            ...request.headers,
            Authorization: 'Bearer ' + rootStore.authStore.token
        }
    }
    return request
})

axios.interceptors.response.use(response=>response,error=>{
    if(axios.isAxiosError(error) && error.response){
        if(error.response.status === 401) rootStore.authStore.logout()
    }
    throw error
})