import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import ICiclo from "interfaces/service/models/ICiclo";
import IVisitador from "interfaces/service/models/IVisitador";

export async function visitadores() {
    const result = await axios.get<IVisitador[]>(API_RESOURCES.OPCIONES.BASE + '/visitadores')
    return result.data
}

export async function ciclos(){
    const result = await axios.get<ICiclo[]>(API_RESOURCES.OPCIONES.BASE + '/ciclos')
    return result.data
}