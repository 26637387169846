import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import IFromToFilter from "interfaces/app/IFromToFilter";

export async function actividades(data: IFromToFilter){
    const result = await axios.get(API_RESOURCES.REPORTES.BASE+'/actividades', {
        params: {
            ...data
        },
        responseType: 'blob'
    })

    return result
}