import axios from "axios"
import API_RESOURCES from "constants/service-resources"
import IActividad from "interfaces/service/models/IActividad"
import qs from "qs"
import MedicoUltVist from "types/service/medico-ult-vis"
import IMedico from "../interfaces/service/models/IMedico"
import IPaginatedResponse from "../interfaces/service/models/IPaginatedResponse"
import IPaginatedRequest from "../interfaces/service/requests/IPaginatedRequest"

export async function getMedicos(params: IPaginatedRequest) {
    const result = await axios.get<IPaginatedResponse<MedicoUltVist>>(API_RESOURCES.MEDICOS.BASE, {
        params: {
            ...params,
        },
        paramsSerializer: (pr: any) => qs.stringify(pr)
    })
    return result.data
}

export async function getMedicosResumidos() {
    const result = await axios.get<IMedico[]>(API_RESOURCES.MEDICOS.BASE + '/resumidos-usuario', {
    })
    return result.data
}

export async function getDatallesMedico(id: IMedico["id"]) {
    const result = await axios.get<IMedico>(API_RESOURCES.MEDICOS.BASE + '/' + id)
    return result.data
}

export async function visitasMedico(id: IMedico["id"], paginationParams: IPaginatedRequest) {
    const result = await axios.get<IPaginatedResponse<IActividad>>(
        API_RESOURCES.MEDICOS.BASE + '/' + id + '/visitas',
        { params: paginationParams }
    )
    return result.data
}

export async function registrarMedico(data: IMedico) {
    const result = await axios.post<IMedico>(API_RESOURCES.MEDICOS.BASE, data)
    return result.data
}

export async function actualizarMedico(params: { id: number, data: IMedico }) {
    const result = await axios.put(API_RESOURCES.MEDICOS.BASE + '/' + params.id, params.data)
    return result
}

export async function eliminarMedico(id: number) {
    const result = await axios.delete(API_RESOURCES.MEDICOS.BASE + '/' + id)
    return result
}

export async function conmutarRevisita(id: number){
    const result = await axios.post(`${API_RESOURCES.MEDICOS.BASE}/${id}/toggle-revisita`)
    return result
}