import api from "api/api";
import { db } from "db";
import ISyncState from "interfaces/app/ISyncState";
import { makeAutoObservable, reaction } from "mobx";
import { RootStore } from "./root-store";

const initalSyncState: ISyncState = {
    isLoading: false,
    success: false
}

const LS_KEYS = {
    IS_SYNCHRONIZED: "isSynchronized"
}

class SyncStore {
    private rootStore: RootStore
    medicosSyncState: ISyncState
    clientesSyncState: ISyncState
    productosSyncState: ISyncState
    isSynchronized: boolean
    isSynchronizing: boolean

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)

        this.rootStore = rootStore
        this.medicosSyncState = initalSyncState
        this.clientesSyncState = initalSyncState
        this.productosSyncState = initalSyncState
        this.isSynchronized = false
        this.isSynchronizing = false
        this.loadFromLocalStorage()
        this.setReactions()

    }

    get isAllSyncSuccess() {
        return (
            this.medicosSyncState.success &&
            this.clientesSyncState.success &&
            this.productosSyncState.success
        )
    }


    async startSyncronization() {
        try {
            this.isSynchronized = false
            this.isSynchronizing = true
            await Promise.all([
                this.sincronizeMedicos(),
                this.sincronizeClientes(),
                this.sincronizeProductos()
            ])
            this.isSynchronized = true
        } finally {
            this.isSynchronizing = false
        }
    }

    private async sincronizeMedicos() {
        try {
            this.medicosSyncState.isLoading = true
            this.medicosSyncState.success = false
            const medicos = await api.sync.medicos()
            db.medicos.clear()
            db.medicos.bulkAdd(medicos)
            this.medicosSyncState.success = true
        } catch (err) {
            this.medicosSyncState.error = err
            throw err
        } finally {
            this.medicosSyncState.isLoading = false
        }
    }

    private async sincronizeClientes() {
        try {
            this.clientesSyncState.isLoading = true
            this.clientesSyncState.success = false
            const clientes = await api.sync.clientes()
            db.clientes.clear()
            db.clientes.bulkAdd(clientes)
            this.clientesSyncState.success = true
        } catch (err) {
            this.clientesSyncState.error = err
            throw err
        } finally {
            this.clientesSyncState.isLoading = false
        }
    }

    private async sincronizeProductos() {
        try {
            this.productosSyncState.isLoading = true
            this.productosSyncState.success = false
            const productos = await api.sync.productos()
            db.productos.clear()
            db.productos.bulkAdd(productos)
            this.productosSyncState.success = true
        } catch (err) {
            this.productosSyncState.error = err
            throw err
        } finally {
            this.productosSyncState.isLoading = false
        }
    }

    private loadFromLocalStorage() {
        const isSynchronizedAsString = localStorage.getItem(LS_KEYS.IS_SYNCHRONIZED)
        if (isSynchronizedAsString !== null) {
            this.isSynchronized = JSON.parse(isSynchronizedAsString)
            return;
        }
        this.isSynchronized = false
    }

    private setReactions() {
        reaction(
            () => this.isSynchronized,
            isSynchronized => {
                localStorage.setItem(LS_KEYS.IS_SYNCHRONIZED, JSON.stringify(isSynchronized))
            }
        )
    }

    clear() {
        this.isSynchronized = false
        localStorage.removeItem(LS_KEYS.IS_SYNCHRONIZED)
    }
}

export default SyncStore