import axios from "axios"
import API_RESOURCES from "constants/service-resources"
import IOpcionesVisHosp from "interfaces/service/models/IOpcionesVisHosp"
import IPaginatedResponse from "interfaces/service/models/IPaginatedResponse"
import IVisitaHospital from "interfaces/service/models/IVisitaHospital"
import IPaginatedRequest from "interfaces/service/requests/IPaginatedRequest"

export async function getList(params: IPaginatedRequest) {
    const result = await axios.get<IPaginatedResponse<IVisitaHospital>>(API_RESOURCES.VISITAS_HOSPITALES.BASE, {
        params: {
            ...params
        }
    })
    return result.data
}

export async function getDetails(id: IVisitaHospital["id"]) {
    const result = await axios.get<IVisitaHospital>(API_RESOURCES.VISITAS_HOSPITALES.BASE + '/' + id)
    return result.data
}

export async function registrar(data: IVisitaHospital){
    const result = await axios.post(API_RESOURCES.VISITAS_HOSPITALES.BASE, data)
    return result
}

export async function destroy(id: number){
    const result = await axios.delete(API_RESOURCES.VISITAS_HOSPITALES.BASE+'/'+id)
    return result
}

export async function getOpciones() {
    const result = await axios.get<IOpcionesVisHosp>(API_RESOURCES.VISITAS_HOSPITALES.BASE + '/opciones')
    return result.data
}